@tailwind base;
@tailwind components;
@tailwind utilities;

/* @media screen and (min-width:768px){
    .lg\:py-20 {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

@media screen and (min-width:768px){
    .md\:-right-10 {
        right: -2.5rem;
    }
    .md\:py-14 {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    }
} */

@media screen and (min-width:480px){
.xxs\:w-76 {
    width: 19rem;
}
}
@media screen and (min-width:980px){
    .max-w-420 {
        max-width: 420px;
    }
    }
/* .-top-12 {
    top: -3rem;
}

.bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55,65,81,var(--tw-bg-opacity));
}
.pr-8 {
    padding-right: 2rem;
}
.pl-5 {
    padding-left: 1.25rem;
}
.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
} */

.overflow-auto{
    -webkit-overflow-scrolling: touch;
}
.overflow-y-auto,.overflow-x-auto{
    -webkit-overflow-scrolling: touch;
}