/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 4, 2023 */



@font-face {
    font-family: 'os-Bold';
    src: url('opensans-bold-webfont.woff2') format('woff2'),
         url('opensans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'os-ExtraBold';
    src: url('opensans-extrabold-webfont.woff2') format('woff2'),
         url('opensans-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'os-Regular';
    src: url('opensans-regular-webfont.woff2') format('woff2'),
         url('opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'os-SemiBold';
    src: url('opensans-semibold-webfont.woff2') format('woff2'),
         url('opensans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}