.public-proposal .min-height-cover-page{
    min-height: 760px;
}
.public-proposal .section-min-height{
 min-height: 600px;
}
.public-proposal .section-heading{
 @apply text-xl sm:text-3xl font-interBold text-titleColor break-all;
}
.public-proposal .section-bg{
    background:#F6F6FF;
}



.public-proposal .section-title{
    font-size:1.6rem;
    line-height: 2rem;
   }
   .public-proposal .section-titleColor{
    color:#3C535F;
   }
   @media (min-width: 480px) {
    .public-proposal .section-title{
      font-size:2rem;
      line-height: 2rem;
     }
  }
.public-proposal .proposalheadColor{
 color: #3C535F;
  }
.public-proposal .proposaltextColor{
 color: #28373E;
  }
.public-proposal .font15{
font-size: 15px;
  }
  
 /***Proposal***/
.proposal-welcome-image-bg{
  background-image: url("../public/images/proposal/welcome-cover-img.jpeg"); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.different-energies{
  background-image: url("../public/images/proposal/different-energies-bg.jpg"); 
}
.proposal-welcome-image-bg .header-section{
  @apply p-8
}
.proposal-welcome-image-bg .header-section .logo{
  max-width: 300px;
  max-height: 114px;
}
.proposal-min-height-cover-page{
  min-height: 666px;

  @media (min-width: 640px) {
      min-height: 720px;
  }
}
.public-proposal .min-height-cover-page{
      min-height: 760px;
}
.public-proposal .section-min-height{
  min-height: 600px;
}
.public-proposal .section-title {
  font-size: 2.8rem;
  line-height: 2.5rem;
}
.public-proposal .section-title.section-residential{
    line-height: 1.1;
}
.proposal-welcome-sub-title{
  @apply text-2xl xxs:text-3xl text-center sm:text-4xl;
  color: #ff8a28;
}
.proposal-welcome-title {
  @apply text-xl text-center sm:text-4xl text-white;
}
.proposal-cover-footer-section {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
}
.proposal-footer-bg {
  background: rgba(107,96,96,.36);
}
.color-blue {
  color: #5bbfff;
}
.proposal-bg-green {
  background: #26B99A;
}
.proposal-bg-light-green{
  background: #99CA3E;
}
.proposal-bg-orange{
  background: #E57D27;
}
.proposal-bg-blue{
  background:#5CABDE;
}
.section-discription{
  color:#666766;
  font-size:1rem;
}
.section-title{
  /* font-size:1.6rem;
  line-height: 2rem; */
  /* color:#3C535F; */
 }
 .section-titleColor{
  color:#3C535F;
 }
.proposal-feature-card::after{
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  bottom:0.25rem;
  background: rgba(0, 0, 0, 0.3);
}
.proposal-feature-title{
  @apply text-xl xl:text-2xl text-white;
  line-height: 1.5rem;
}
.proposal-feature-discription{
  line-height: 1.3rem;
  font-size: 15px;
  color: #fff;
}
.proposal-section-bg{
  background:#F6F6FF;
}
.list-squire-blue{
  background-color: #5cabde;
}
.list-squire-orange {
  background-color: #e57d27;
}
.public-proposal .traditional-bg-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-image: url('../public/images/proposal/traditional-data-bg.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.public-proposal .energy-traditional-bg-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-image: url('../public/images/proposal/different-energies/power-vs-traditional.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.proposal-faqs-section .proposaltextColor,
.proposal-section-info .proposaltitleColor ,
.proposal-section-info .proposaltextColor{
  color: #3c535f;
}
.proposal-faqs-section .greenWith {
  fill: #99ca3e;
}
.proposal-faqs-section .textBlue {
  color: #5baade;
  box-shadow: 0 4.7205px 4.7205px #00000040;
}
.proposal-section-info .proposalheadColor {
  color: #686868;
}
.bg-orangeLight {
  --tw-bg-opacity: 1;
  background-color: rgba(254,176,25,var(--tw-bg-opacity));
}
.proposal-section-info .propsalTextPrimary {
  color: #e57d27;
}
.bill-overlay-img {
  background: url('../public/images/proposal/ss-bill-detail.png') no-repeat center;
  background-size: cover;
}
.proposal-bills-section .text-white{
  color: #fff;
}
.design-img-max-heigh{
  max-height: 320px;
  min-height: 300px;
  background-color: #ebebeb;
}
.financial-toggle-button .inactive{
  margin-left: -25px;
}
.financial-toggle-button .pay-incative{
  margin-right: -25px;
}

@media (max-width: 1024px){
  .proposal-feature-card {
      min-height: 260px;
      max-height: 260px;
  }
}

@media screen and (min-width: 1280px){
  .proposal-welcome-title {
    font-size: 3.75rem;
    line-height: 1;
  }
}
@media screen and (min-width: 640px){
  .proposal-welcome-title{
      font-size: 3rem;
      line-height: 1;
  }
  .proposal-welcome-sub-title{
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .design-img-max-heigh{
    max-height: 520px;
    min-height: 500px;
    background-color: #ebebeb;
}
}
@media screen and (min-width: 480px){
  .proposal-welcome-sub-title{
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
} 
@media screen and (max-width: 640px){
  .public-proposal .section-title {
    font-size: 2rem;
    line-height: 1.3;
  }
}
@media screen and (max-width: 480px){
  .public-proposal .section-title {
    font-size: 1.85rem;
    line-height: 1.3;
  }
}

/*******different energies******/

.different-energies-bg{
  background: url('../public/images/proposal/different-energies/different-energies-bg.jpg') no-repeat center;
  background-size: cover;
}
.blue-380{
  color: #3FBBFE
}
@media (min-width: 981px){
  .max-w-86{
    max-width: 86%;
  }
}

.diff-energies-section .traditional-bg-img{
  @apply w-full h-full object-cover;
  background-size: cover;
  background-position: center;
  background-size: cover;
  background: url("../public/images/proposal/different-energies/align-solar-protection-warrenty.webp") no-repeat;
  background-size: cover;
  position: relative;
  }
  .diff-energies-section .traditional-bg-img:before{
    content: '';
    background: #2f2f2f;
    opacity: .15;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height:100%;

  }
  .diff-energies-section .imageSvg{
  height: 100%;
  display: flex;
  flex-shrink: 0;
  width: 2.25rem;
  }
  .diff-energies-section .copyRight{
      font-size: 9px;
  }
  .diff-energies-section .warranty{
      font-size: 11px;
  }