.spl__estimate-design.estimate-design  .chooseOffsetTab{
  max-height: 100vh;
  overflow: auto;
  }
  .spl__designToolBox{
    max-height: 100vh;
    overflow: auto;
    }
  .carousel-item-padding:not(:last-child) {
    padding-right: 15px; /* Add margin to all items except the last one */
  }
  .carousel-item-padding .box-height{
    margin: 0px;
  }
  .spl__switch{
    position: relative;
  }
  .spl__switch input[type=checkbox] {
    display: none;
    /* toggle in the OFF state */
    /* toggle in the ON state */
    /* This is the part that activates the background when the checkbox is checked */
  }
  .spl__switch .switch-btn {
    position: relative;
    display: block;
    width: 51px;
    height: 25px;
    border-radius: 40px;
    border: 0px;
    transition: transform 200ms cubic-bezier(0.41, -0.01, 0.63, 1.09);
    cursor: pointer;
    background: #DBDDE9;
  }
  .spl__switch .switch-btn::before,  .spl__switch .switch-btn::after {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 23px;
    height: 23px;
    border-radius: 36px;
    content: "";
    transition: all 220ms cubic-bezier(0.76, 0.01, 0.15, 0.97);
  }
  .spl__switch .switch-btn::before {
    /* background-image: linear-gradient(45deg, #fff 10%, #fff 90%); */
  }
  .spl__switch input[type=checkbox]:checked ~ .switch-btn::before {
    /* background-image: linear-gradient(45deg, #f3953e 10%, #f3953e 90%); */
  }
  .spl__switch .switch-btn::after {
    background-color: #fff;
  }
  .spl__switch input[type=checkbox]:checked ~ .switch-btn {
    @apply bg-primary
  }
  .spl__switch .switch-btn::after {
    box-shadow: 1px 0 4px rgba(0, 0, 0, 0.1);
}
  .spl__switch input[type=checkbox]:checked ~ .switch-btn::before {
    width: 54px;
  }
  .spl__switch input[type=checkbox]:checked ~ .switch-btn::after {
    transform: translateX(26px);
    background-color: #fff;
    box-shadow: -4px 0 4px rgba(0, 0, 0, 0.1);
  }
  .spl__switch input[type=checkbox]:checked ~ div {
    opacity: 1;
    visibility: visible;
    transition: opacity 240ms, visibility 0s;
    animation: rot 10s linear infinite;
  }
  .ddd{
    @apply fill-current text-primary
  }
  label.checkbox-container.spl__checkbox ,label.checkbox-container.spl__checkbox .checkbox-mainDiv {
    height: 20px;
}

 
  .checkbox-container.spl__checkbox input {
    width: 20px;
    height: 20px;
}
.checkbox-container.spl__checkbox .checkbox-mainDiv .check {
    width: 20px;
    height: 20px;
}
.checkbox-container.spl__checkbox .check::after {
    left: 5.5px;
    top: 1px;
    width: 6px;
    height: 12px;
}
.spl__popularStrip{
    font-size: 10px;
    margin-right: -0.85rem;
    @screen md{
            margin-right: -1.05rem;
    }
}
.spl__react-multi-carousel-track .react-multi-carousel-track{
  padding-bottom: 10px;
}
.single-page-layout{
  padding-bottom: 20px;
}
.estimateDesignMobile.single-page-layout{
  padding-bottom: 60px;
  @screen md{
    padding-bottom: 80px;
  }

}
.step-page-layout-wrapper header.relative {
  display: none;
}
/* .single-page-layout-wrapper .spl__footer{
  display: none;
} */

.spl__brandLogo{
  @apply  w-full ;
  max-height: 45px;
  @screen md{
    max-height: 57px;
  }
}
.spl__headerWrapper{
  @apply  flex-row
}
.spl__mostPopularSpan{
  /* background: var(--primary); */
  background: #ff932b;
  text-align: center;
  font-size: 12px;
  padding: 6px 8px;
  line-height: 1;
  border-radius: 3px;
  color: #fff;
}
.battery_mostpopular {
  padding: .58rem 1rem !important;
  margin-left: 10px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: var(--primaryMedium),sans-serif;
  text-wrap: nowrap;
}
.mostPop .select-box-wrap .css-yk16xz-control > div:first-child{
  padding-right: 98px !important;
}
.spl__heading{
  color: #0C3668;
}
.spl__labelTitle{
  color: 2D384C;
}
.spl__bgGradient{
  background: linear-gradient(0deg, #F2F5F9, #F2F5F9), linear-gradient(180deg, #FFFFFF 0%, #EDF6FF 72.85%, #E7F3FF 100%);
border-radius: 3px;
}
.spl__image-size {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 100%;
  max-height: 221px;
}
.spl__financeRadio {
  @apply flex flex-row;
  transition: border 0.2s ease;
}

.spl__financeRadio input {
  vertical-align: middle;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: none;
  border: 0;
  box-shadow: inset 0 0 0 4px #fff;
  appearance: none;
  padding: 0;
  margin: 0;
  transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
  pointer-events: none;
  border: 1px solid #ccc;
  background: #fff;
}
.spl__financeRadio input:focus {
  outline: none;
}
.spl__financeRadio.selected input{
  box-shadow: inset 0 0 0 5px #ffffff;
  background: var(--primary);
  border: 1px solid var(--primary);
}
.spl__financeRadio span {
  vertical-align: middle;
  display: inline-block;
  line-height: 20px;
  padding: 0 8px;
}

.spl__home-appliances .qty-change-btns{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.spl__headingHeight{
  height: 4.625rem;
}
.checkbox-container.spl__smalCounter .checkbox-mainDiv .check {
  width: 17px;
  height: 17px;
  border: 2.3px solid #5A5B5D;
}
.checkbox-container.spl__smalCounter input:checked ~ .check {
  background: #fff;
  border: 2.3px solid #5A5B5D !important;
}
.checkbox-container.spl__smalCounter .check::after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border-color: #5a5b5d;
}
.handleEdit{
  @apply bg-white px-3 py-1 text-titleColor border-titleColor border rounded text-sm font-interMedium hover:text-bodyColor hover:shadow;
}

/* Faq Ui  */
.spl__faqWrapper{
  @apply flex justify-between font-primarySemiBold  bg-gray-100 border-t border-gray-50
}
.spl__faqTab{
  @apply capitalize w-full p-3 sm:px-6 border-b border-gray-200 text-titleColor
}
.spl__faqTab.--active{
  @apply  border-primary text-primary bg-white
}
.nodataFound{
  @apply m-auto pt-4 px-4 sm:px-6 py-4 text-center text-gray-400 font-interLight text-base
}
.spl__darkblue150{
  color:#1558A6
}
.spl__disclaimer{
  color:#7E838F
}
.spl__label_order{
  color: #586E88
}
.eerorText{
 font-size: 11px;
}

.downward-scroll-arrow{
  position: fixed;
  bottom: 60px;
  right: 10px;
  z-index:10000;
}
.spl__premiumInput{
  /* position: absolute; */
  /* right: 50px; */
  /* background: var(--primary); */
  text-align: center;
  font-size: 12px;
  background: #fff;
  padding: 5px 8px;
  border-radius: 3px;
  line-height: 1;
  border-radius: 3px;
  display: flex;
  align-items: center;
  box-shadow:rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.single_page-title{
  color: #263238;
  font-size: 1.5rem;
  font-weight: 600;
}
.spl__premiumInput span{
  line-height: 1;
}
.spl__premiumInput svg{
  top: 0px
}
.panel-select .css-1uccc91-singleValue,
.inverter-select .css-1uccc91-singleValue{
  width: 100%;
}
.panel-select .spl__mostPopularSpan{
  position: static;
}
.w-58{
  width: 58px;
}