@import "./font-open-sans/font-open-sans.css";




/* .page-height{
  min-height: calc(100vh - 5rem);
} */
.suntuity-landing.suntuity-home-banner {
    background: url("../public/images/landing-img/suntuity/banner-2.png");
    background-size: cover;
    background-position:bottom center;
    object-fit: cover;
    background-repeat: no-repeat;
    object-position: center;
   
  }
.suntuity.suntuity-four-step-banner {
  background: linear-gradient(180deg, #F8FBFD 0%, #FFF 100%);

  }
  .suntuity.states-bg {
    background-color: #F2F1F1;
  }
  .suntuity.landing-gray{
    background-color: #F2F1F1;
  }
  .suntuity.roof-top-solar-bg{
    background: #e8f3ff;
    background: linear-gradient(180deg, #E7F3FF 0%, rgba(247, 252, 255, 0.00) 100%);
  }
  .suntuity.testimonial-bg{
    background-color: #F8F8F8;
  }
  .suntuity.testomonial-card .paragraph-section{
    min-height: 190px;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  .suntuity.testomonial-card .testimonial-bg{
    border-radius: 18px;
    background: linear-gradient(0deg, #F8F8F8 0%, #F8F8F8 100%), #FFF;
    box-shadow: 0px 8px 26px 0px rgba(82, 78, 78, 0.10);
  }
  /* .section-heading {
    @apply text-2xl leading-7 sm:text-3xl md:text-3xl xl:text-4xl xd:text-4xxl mb-2 xxs:mb-2.5 sm:mb-3 xl:mb-3.5 xd:mb-4 text-titleColor font-sfBold;
  }
  .section-paragraph {
    @apply text-base sm:text-lg sm:leading-7 xl:text-xl font-sfMedium;
    color: #565c63;
  } */
  .map-svg-wrap {
    padding: 0px 6px;
}




  /* media query */


  @media (min-width:1025px) {
    .page-height{ min-height: calc(100vh - 5rem); }
   } 
  
  @media (min-width: 768px) and (max-width: 1024px){
    .page-height{ min-height: 600px; }
  }
  /* @media (orientation: landscape) {
    .page-height{  min-height: 600px; }
  } */
  @media (max-width : 1023px){
    .map-svg-wrap svg { height: auto; }
  }
  

  @media (max-width: 640px) {
    .suntuity-landing.suntuity-home-banner {
      background: url("../public/images/landing-img/suntuity/mobile-banner.png");
      background-size: cover;
      background-position: center;
      object-fit: cover;
      background-repeat: no-repeat;
      object-position: center;
    }
    .page-height{
      min-height: calc(100vh - 7rem);
    }
    .suntuity.testomonial-card .paragraph-section{
      min-height: 260px;
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
    .suntuity.testomonial-card .testimonial-bg{
      border-radius: 12px;
      background: linear-gradient(0deg, #F8F8F8 0%, #F8F8F8 100%), #FFF;
      box-shadow: 0px 8px 26px 0px rgba(82, 78, 78, 0.10);
    }
    .suntuity.roof-top-solar-bg{
      background: #e8f3ff;
      background: linear-gradient(180deg, #E7F3FF 0%, rgba(247, 252, 255, 0.00) 100%);
    }
}