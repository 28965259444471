@font-face {
    font-family: "Panton-Thin";
    src: url("Panton-Thin.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Panton-Bold";
    src: url("Panton-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Panton-ExtraBold";
    src: url("Panton-ExtraBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Panton-Light";
    src: url("Panton-Light.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Panton-Regular";
    src: url("Panton-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Panton-SemiBold";
    src: url("Panton-SemiBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "ProximaNova-Black";
    src: url("ProximaNova-Black.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "ProximaNova-Bold";
    src: url("ProximaNova-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "ProximaNova-Extrabld";
    src: url("ProximaNova-Extrabld.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "ProximaNova-Light";
    src: url("ProximaNova-Light.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "ProximaNova-Medium";
    src: url("ProximaNova-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "ProximaNova-Regular";
    src: url("ProximaNova-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "ProximaNova-Semibold";
    src: url("ProximaNova-Semibold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "sq-icons";
    src: url("sq-icons.eot?dp5l9k");
    src: url("sq-icons.eot?dp5l9k#iefix") format("embedded-opentype"),
      url("sq-icons.ttf?dp5l9k") format("truetype"),
      url("sq-icons.woff?dp5l9k") format("woff"),
      url("sq-icons.svg?dp5l9k#sq-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="sq-"],
  [class*=" sq-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "sq-icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .sq-air-conditioning:before {
    content: "\e900";
  }
  .sq-computer:before {
    content: "\e901";
  }
  .sq-electric-rance-oven:before {
    content: "\e902";
  }
  .sq-ev-charger:before {
    content: "\e903";
  }
  .sq-extra-fridge-freezer:before {
    content: "\e904";
  }
  .sq-fans:before {
    content: "\e905";
  }
  .sq-fridge-microwave-icon:before {
    content: "\e906";
  }
  .sq-furnace:before {
    content: "\e907";
  }
  .sq-garage:before {
    content: "\e908";
  }
  .sq-internet-router:before {
    content: "\e909";
  }
  .sq-Lights-plugs:before {
    content: "\e90a";
  }
  .sq-medical-equipment:before {
    content: "\e90b";
  }
  .sq-pool-pump:before {
    content: "\e90c";
  }
  .sq-security-system:before {
    content: "\e90d";
  }
  .sq-tv:before {
    content: "\e90e";
  }
  .sq-washer-dryer:before {
    content: "\e90f";
  }
  .sq-water-heater:before {
    content: "\e910";
  }
  .sq-well-pump:before {
    /* content: "\e911"; */
    content: "\e922";
  }
  .sq-site-survey:before {
    content: "\e912";
  }
  .sq-design:before {
    content: "\e913";
  }
  .sq-permitting:before {
    content: "\e914";
  }
  .sq-install:before {
    content: "\e915";
  }
  .sq-inspection:before {
    content: "\e916";
  }
  .sq-pto:before {
    content: "\e917";
  }
  .sq-complete:before {
    content: "\e918";
  }
  .sq-check:before {
    content: "\e919";
  }
  .sq-bathrooms:before {
    content: "\e91a";
  }
  .sq-bedrooms:before {
    content: "\e91b";
  }
  .sq-kitchen:before {
    content: "\e91c";
  }
  .sq-laundry-room:before {
    content: "\e91d";
  }
  .sq-living-room:before {
    content: "\e91e";
  }
  .sq-medical-device:before {
    content: "\e91f";
  }
  .sq-pool-pump1:before {
    content: "\e920";
  }
  .sq-water-heater1:before {
    content: "\e921";
  }
  .sq-well-pump-battery:before {
    content: "\e922";
  }
  
  @font-face {
    font-family: "Catamaran-Black";
    src: url("Catamaran-Black.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Catamaran-Bold";
    src: url("Catamaran-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Catamaran-ExtraBold";
    src: url("Catamaran-ExtraBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Catamaran-SemiBold";
    src: url("Catamaran-SemiBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Catamaran-Medium";
    src: url("Catamaran-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Catamaran-Regular";
    src: url("Catamaran-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Config-Regular";
    src: url("config-condensed/ConfigCondensedRegular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Config-Text";
    src: url("config-condensed/ConfigCondensedText.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Config-Light";
    src: url("config-condensed/ConfigCondensedLight.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Config-Medium";
    src: url("config-condensed/ConfigCondensedMedium.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Config-SemiBold";
    src: url("config-condensed/ConfigCondensedSemiBold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Config-Bold";
    src: url("config-condensed/ConfigCondensedBold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Config-Black";
    src: url("config-condensed/ConfigCondensedBlack.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Sf-Bold';
    src: url('sf-font/sf-ui-display-bold-webfont.woff2') format('woff2'),
         url('sf-font/sf-ui-display-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sf-Medium';
    src: url('sf-font/sf-ui-display-medium-webfont.woff2') format('woff2'),
         url('sf-font/sf-ui-display-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sf-Regular';
    src: url('sf-font/sf-ui-display-regular-webfont.woff2') format('woff2'),
         url('sf-font/sf-ui-display-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sf-SemiBold';
    src: url('sf-font/sf-ui-display-semibold-webfont.woff2') format('woff2'),
         url('sf-font/sf-ui-display-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Sf_Light';
  src: url('sf-font/sf-ui-display-light-webfont.woff2') format('woff2'),
       url('sf-font/sf-ui-display-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}