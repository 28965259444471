
  @font-face {
    font-family: 'interThin';
    src: url('Inter-Thin.ttf') format('truetype'),
         url('Inter-Thin.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
  @font-face {
    font-family: 'interLight';
    src: url('Inter-Light.ttf') format('truetype'),
         url('Inter-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'interExtraLight';
    src: url('Inter-ExtraLight.ttf') format('truetype'),
         url('Inter-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
	font-family: 'interRegular';
	src: url('Inter-Regular.ttf')  format('truetype');
	font-weight: normal;
	font-style: normal;
  }


  @font-face {
    font-family: 'interMedium';
    src: url('Inter-Medium.ttf') format('truetype'),
         url('Inter-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
  @font-face {
    font-family: 'interSemiBold';
    src: url('Inter-SemiBold.ttf') format('truetype'),
         url('Inter-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'interBold';
    src: url('Inter-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'interExtraBold';
    src: url('Inter-ExtraBold.ttf') format('truetype'),
         url('Inter-ExtraBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
