.clip-path-arrow-both {
  clip-path: polygon(4% 0, 96% 0, 100% 50%, 96% 100%, 4% 100%, 0% 50%);
}
.hover-item {
  position: relative;
}
.hovered-data {
  display: none;
}
.rotate-45 {
  transform: rotate(45deg);
}
.hover-item:hover .hovered-data {
  display: flex;
}

.tabs .tabs__tab.tabs__tab--selected::after {
  @apply w-full bg-primary absolute h-0.5;
  content: "";
  bottom: -2px;
}
@media screen and (max-width: 768px) {
  .ReactModal__Content--after-open {
    max-width: 100vw;
    margin: 0 auto;
    border-radius: 2px !important;
    padding: 0 !important;
    /* padding for live chat icon */
    /* padding-bottom: 65px !important; */
  }
  .AmountModal__Overlay.recomdation-battery .ReactModal__Content--after-open {
    margin-top: 0;
    min-width: 100vw;
    max-width: 100vw;
    /* min-height: 100vh;
        max-height: 100vh; */
    min-height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);
  }
}

@media screen and (max-width: 480px) {
  #AcceptUIContainer {
    margin-left: 0px !important;
  }
  .accordion__button:before {
    display: inline-block;
    content: "";
    height: 10px;
    width: 10px;
    margin-right: 0px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
    position: absolute;
    right: 4px;
    margin-top: 10px;
  }

  .info-box:hover .info-data {
    @apply z-10 block shadow-md  p-4 absolute left-0 right-0 mx-auto w-11/12 text-white font-primaryRegular text-sm bg-black bg-opacity-75 top-5 rounded;
  }
}
