/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.initialUserDetailForm.leadInitialForm
  .ReactModal__Content.ReactModal__Content--after-open {
  width: 100%;
  inset: 0px;
  -webkit-transform: translate(0px, 0px) !important;
  -ms-transform: translate(0px, 0px) !important;
  transform: translate(0px, 0px) !important;
  max-width: 410px;
  right: 0 !important;
  margin: 0 !important;
  height: 100vh !important;
  max-height: 100vh !important;
  top: 0px !important;
  border-radius: 0px !important;
  inset: 0px !important;
  left: auto !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.initialUserDetailForm.leadInitialForm.singlePageLAyout
  .ReactModal__Content.ReactModal__Content--after-open {
    align-items: start;
  }

.initialUserDetailFormMobile.initialUserDetailForm.leadInitialForm
  .ReactModal__Content.ReactModal__Content--after-open {
  width: 100% !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  max-width: 100%;
  right: 0 !important;
  margin: 0 !important;
  height: 330px !important;
  max-height: 100vh !important;
  top: 0px !important;
  border-radius: 0px !important;
  inset: 0px !important;
  left: 0px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  bottom: 0;
  bottom: 0 !important;
  top: auto !important;
  @screen sm {
    height: 350px !important;
  }
  @screen md {
    height: 380px !important;
  }
}
.estimateFooter {
  background: #ffffff;
  -webkit-box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);
  /* height: 60px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.estimateFooter.desktop {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.designToolBox {
  background: #ffffff;
  -webkit-box-shadow: 0px 3px 25px rgba(65, 65, 65, 0.1);
  box-shadow: 0px 3px 25px rgba(65, 65, 65, 0.1);
  border-radius: 8px;
  max-height: 100vh;
  overflow: auto;
}
.ribbon-design {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon-design::before,
.ribbon-design::after {
  position: absolute;
  z-index: 0;
  content: "";
  display: block;
  border: 7px solid #0b467b;
}
.ribbon-design div {
  position: absolute;
  display: block;
  width: 225px;
  padding: 8px 0;
  background-color: #2386e1;
  color: #fff;
  font: 700 12px/1.2 "inter-regular", sans-serif;
  text-transform: capitalize;
  text-align: center;
}
.ribbon-design-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-design-top-right::before,
.ribbon-design-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-design-top-right::before {
  top: -5px;
  left: 30px;
  border-top-left-radius: 24px;
}
.ribbon-design-top-right::after {
  bottom: 30px;
  right: -5px;
  border-bottom-right-radius: 24px;
}
.ribbon-design-top-right div {
  left: -5px;
  top: 37px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.EstimateHeight {
  height: 70vh;
  overflow: auto;
  display: block;
  padding-top: 13px;
}
.MobileHeight.EstimateHeight {
  height: 85vh;
}
.optionalTag {
  background: #2386e1;
  border-radius: 100px;
}
.optionalTagisClientOrg.optionalTag {
  background: var(--secondary);
}
.bg-custom {
  background-color: #eef3fd;
}
.apr-textColor {
  color: #073265;
}
.list-message.error-msg {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  @apply px-4 xxs:px-8 md:px-16 py-2 bg-blue-600 text-superSecondary bg-opacity-10 text-center leading-4 text-titleColor font-primaryMedium rounded-md mt-4 mx-auto;
}
.panelDialog.specs-height {
  height: 205px;
}
.ribbon.customRib div {
  font: 700 10px/1 "Lato", sans-serif;
}
.ribbon-top-right.customRib {
  top: -10px;
  right: -10px;
}

.ribbon-top-right.customRib::before {
  top: -5px;
  left: 51px;
}
.ribbon-top-right.customRib::after {
  bottom: 51px;
  right: -5px;
}
.ribbon-top-right.customRib div {
  left: 1px;
  top: 26px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.batteryRecomdationsTab__innerList {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: -webkit-max-content 1rem auto;
  -ms-grid-rows: max-content 1rem auto;
  grid-template-rows: -webkit-max-content auto;
  grid-template-rows: max-content auto;
  height: 100%;
  gap: 1rem;
}
.customImageHeight {
  width: 100%;
  max-width: 100%;
  max-height: 320px;
  min-height: 165px;
}
.customBannerUI {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: -webkit-max-content auto;
  -ms-grid-rows: max-content auto;
  grid-template-rows: -webkit-max-content auto;
  grid-template-rows: max-content auto;
}
.customBannerHeight {
  height: 100%;
}
.customLogoWidth {
  max-width: 6rem;
  @screen xxs {
    max-width: 7rem;
  }
  @screen md {
    max-width: 10rem;
  }
}
.allwidth.customLogoWidth {
  max-width: 13.75rem;
  @screen xxs {
    max-width: 10rem;
  }
  @screen md {
    max-width: 10rem;
  }
}
.vinitLogoImage {
  max-width: 9rem;
}

.disclaimerSection {
  background-color: #ffeec2;
  -webkit-box-shadow: 0px 2px 4px #f1c74c;
  box-shadow: 0px 2px 4px #f1c74c;
  border-radius: 4px;
}
.lead-form-dialog.onlyFaq {
  position: relative;
  /* max-height: 80vh; */
  /* min-height: 80vh; */
  overflow: auto;
  max-height: 80vh;
  height: auto;
}
/* .mobileView.lead-form-dialog{
height: 100vh;
} */
.AmountModal__Overlay.TalkToExpertDialog .ReactModal__Content--after-open {
  min-width: 356px;
  min-height: auto;
}
@media screen and (min-width: 980px) {
  .customBannerUI {
    min-height: calc(100vh - 80px);
    height: 100%;
  }
  .customBannerHeight {
    height: calc(100vh - 5rem);
  }
}
@media screen and (max-width: 980px) {
  .mobileView.lead-form-dialog {
    height: 90vh;
  }
  .mobileView.lead-form-dialog.leadformCustom {
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .mobileView.lead-form-dialog {
    height: 100vh;
  }
  .mobileView.lead-form-dialog.leadformCustom {
    max-height: 100vh;
    height: 100vh;
  }
  .lead-form-dialog > form {
    height: 100%;
  }
}

/*****Instant Estimate*****/
.instant-estimate-map-wrap {
  background: #ffffff;
  -webkit-box-shadow: 0px 3px 25px rgba(65, 65, 65, 0.1);
  box-shadow: 0px 3px 25px rgba(65, 65, 65, 0.1);
  border-radius: 8px;
  max-height: 100vh;
  overflow: auto;
}
.instant-estimate-100vh-inner {
  min-height: calc(100vh - 7.5rem);
}
.instant-estimate-tool-wrap {
  background: #fff;
  -webkit-box-shadow: 0 3px 25px rgba(65, 65, 65, 0.1);
  box-shadow: 0 3px 25px rgba(65, 65, 65, 0.1);
  border-radius: 8px;
  max-height: 100vh;
  overflow: auto;
  padding: 1.25rem 1.25rem;
}
.estimate-modal-wraper-max-width .ReactModal__Content--after-open {
  max-width: 760px !important;
}

@media screen and (max-width: 639px) {
  .customBannerUI {
    height: 100%;
  }
  .customBannerHeight {
    height: 100%;
  }
  .instant-estimate-100vh-inner {
    min-height: calc(100vh - 11rem);
  }
}
.CarouselCenter .react-multi-carousel-track {
  margin: 0 auto;
}
.suntuityBg {
  background-image: url("../public/images/sq-banner-img-suntuity.jpg");
}
.vivintyBg {
  background-image: url("../public/images/sq-banner-img-vivint.jpg");
}
.solarscoutBg {
  background-image: url("../public/images/solarscoutbg.jpeg");
}

.secondary-date {
  @apply whitespace-nowrap font-normal text-xs xxs:text-sm text-titleColor py-2 px-2.5 sm:px-4 border flex flex-row items-center gap-1 transition duration-150 ease-in-out rounded justify-center;
}
.primary-date {
  @apply bg-primary border-primary transition duration-150 ease-in-out text-white justify-center;
}
.appointment__selectedSlot {
  @apply text-xs font-interMedium text-titleColor;
}
.appointment__dateGrid {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-2;
}
.appointment__dateSlot {
  @apply text-base font-primaryBold mb-2 text-titleColor;
}
.customThemeButton {
  background: var(--buttonBackground);
  color: var(--buttonText);
}
.customThemeButton.text-white {
  color: #fff;
}
.checkbox-container .checkbox-mainDiv .check {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 22px;
  height: 22px;
  background: #fff;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid rgb(224, 224, 224);
  -webkit-box-shadow: 0px 0 1px #d0caca;
  box-shadow: 0px 0 1px #d0caca;
}
.hide-overflow-scroll {
  overflow: hidden;
}
.form-control-overlay.whatNextModal .ReactModal__Content--after-open {
  padding-top: 0px !important;
  width: 100% !important;
  max-width: 1200px;
}
.form-control-overlay.leadformModal.paymentModalWraper
  .ReactModal__Content--after-open {
  max-width: 100%;
  max-height: 80vh;
  min-width: 80%;
  padding: 20px 0 !important;
  @screen md {
    max-width: 90%;
    min-width: 70%;
  }
  @screen xl {
    min-width: 40%;
  }
}
@media screen and (min-width: 480px) {
  .customLogoWidth {
    max-width: 7rem;
  }
  .allwidth.customLogoWidth {
    max-width: 10rem;
  }
}
@media screen and (min-width: 768px) {
  .form-control-overlay.leadformModal.paymentModalWraper
    .ReactModal__Content--after-open {
    max-width: 90%;
    min-width: 70%;
  }
  .customLogoWidth {
    max-width: 10rem;
  }
  .allwidth.customLogoWidth {
    max-width: 13.75rem;
  }
}
@media screen and (min-width: 1280px) {
  .form-control-overlay.leadformModal.paymentModalWraper
    .ReactModal__Content--after-open {
    min-width: 40%;
  }
}
.heightMin {
  min-height: 200px;
}

/* //Common Dailogs UI for estimate Page */
.commonDialogUi__Overlay {
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.commonDialogUi__Overlay .ReactModal__Content.ReactModal__Content--after-open {
  max-width: 1200px;
  width: 100%;
  border-radius: 15px !important;
  border: none !important;
}
@media screen and (min-width: 1400px) {
  .commonDialogUi__Overlay
    .ReactModal__Content.ReactModal__Content--after-open {
    max-width: calc(1400px - 8rem);
  }
}
.commonDialogUi__Overlay.BatteryTypeDialog
  .ReactModal__Content.ReactModal__Content--after-open {
  max-width: 100%;
  width: 100%;
}
@media screen and (min-width: 640px) {
  .commonDialogUi__Overlay.BatteryTypeDialog
    .ReactModal__Content.ReactModal__Content--after-open {
    max-width: 640px;
  }
}

.custom-radio {
  @apply flex flex-row;
  cursor: pointer;
  transition: border 0.2s ease;
  &.updown {
    @apply flex-col;
  }
}
/* .custom-radio.selected{
  @apply border border-primary;
} */
.custom-radio input {
  vertical-align: middle;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: none;
  border: 0;
  box-shadow: inset 0 0 0 4px #fff;
  appearance: none;
  padding: 0;
  margin: 0;
  transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
  pointer-events: none;
  border: 1px solid #ccc;
  background: #fff;
}
.custom-radio input:focus {
  outline: none;
}
.custom-radio.selected input {
  box-shadow: inset 0 0 0 5px #ffffff;
  background: var(--primary);
  border: 1px solid var(--primary);
}
.custom-radio span {
  vertical-align: middle;
  display: inline-block;
  line-height: 20px;
  padding: 0 8px;
}
.fianaceBg {
  background: #f7f8fd;
}
.lead-form-dialog.leadformCustom.isSideBarPopup {
  position: relative;
  max-height: 100vh;
  height: auto;
}
.linkMore {
  position: relative;
}
.linkMore:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: var(--secondary);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.linkMore:hover:after {
  width: 100%;
  left: 0;
}

.step-page-layout .estimate-inner {
  display: grid;
  grid-template-rows: auto max-content;
}
.estimate-inner {
  position: relative;
  height: 100%;
  padding-bottom: 0 !important;
}
.withDesignTool.estimate-inner {
  display: grid;
  grid-template-rows: auto max-content;
  position: relative;
  height: calc(100%);
  padding-bottom: 0 !important;
  @screen md {
    height: calc(100% - 4rem);
  }
}
.single-page-layout .chooseOffsetTab {
  height: 85vh;
}
@media screen and (max-width: 768px) {
  .commonDialogUi__Overlay
    .ReactModal__Content.ReactModal__Content--after-open {
    max-width: 100%;
    max-height: calc(var(--vh, 1vh) * 100);
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    border-radius: 0 !important;
  }
  .EstimateDetailModalPortal .lead-form-dialog.leadformCustom {
    max-height: inherit;
  }
  .EstimateDetailModalPortal .ReactModal__Overlay--after-open {
    padding: 10px 10px 12px !important;
  }

  .mob-max-200 {
    max-width: 200px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .instant-estimate-tool-wrap {
    padding: 1.25rem 0.75rem;
  }
}

.design-tool-heading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @screen md {
    flex-direction: row;
  }
}

.design-tool-address {
  flex-direction: row;
  display: flex;
}
.customEstimateUnit {
  color: var(--tertiary);

}


.step-page-layout-wrapper .main-header {
  display: none;
}

.dynamic-disclosure-content{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.no-coupon-wrap{
  background: #f9fafb;
  color: #8A8B95;
}
.premium-flag {
  background: #447DC1;
  position: absolute;
  right: 0;
  color: #fff;
  padding: 5px 8px 5px 12px;
  border-radius: 14px 0px 0px 14px;
  top: 6px;
  font-size: 12px;
  font-style: italic;
}
.annaul-bill-switch.spl__switch .switch-btn{
  width:42px;
  height: 24px;
}
.annaul-bill-switch.spl__switch input[type=checkbox]:checked ~ .switch-btn::before{
  width: 42px;
}
.annaul-bill-switch.spl__switch input[type=checkbox]:checked ~ .switch-btn::after{
  transform: translateX(17px);
}
.annaul-bill-switch.spl__switch .switch-btn::before, 
.annaul-bill-switch.spl__switch .switch-btn::after{
  width: 22px;
  height: 22px;
}
.premium-tag-order-title{
  margin-right: -15px;
}

.homeonwer-modal-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeonwer-modal-box label {
  width: 142px;
  height: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.homeonwer-modal-box label input {
  position: absolute;
  top: 10px;
  left: 10px;
}
.fill-primary{
  fill: var(--primary);
}
.fill-gray-320{
 fill: #D0D0CF
}

input.submit-credit-btn {
  background: url('../public/images/angle-right-side.png') no-repeat;
  background-size: 42px 42px;
  background-position: center;
  font-size: 0;
  width: 42px;
  height: 42px;
  color: transparent;
  position: relative;
  left: -16px;
}
.finance-card-with-bg .list__items{
  background: #f7f8fd;
}

.unlock-img-bg{
  background: url('../public/images/unlock-finance-img.png');
  background-size: cover;
}
.unlock-img-full{
  min-height: 490px;
  height: 100%;
  opacity: 0;
}
@media (min-width: 1800px){
  .unlock-img-full {
    height: calc(100vh - 22vh);
  }
}
span.mobile-unlock-price-btn {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 481px){
 .unlock-img-bg .unlock-img-span {
    top: 48%;
    position: fixed;
  }
}
